
@media (max-width: 1024px) {
    .small-card {
    margin: auto 20px;
    }
}
@media (max-width:768px) {
    .arrow {
        height: 44px;
        padding: 5px  20px;
    }
    .title {
        height: 38px;
        padding: 5px;
    }
}

@media (max-width: 540px) {
   

    .memberTxt{
        font-size: 1.1rem;
    }

    .memberBtn p{
        padding: 2px 15px ;
        margin-right: 0.1rem;
        font-size: 30px;
    }
    .memberBtn {
        /* padding: 2px 15px ; */
        margin-right: -0.5rem;
    }


}
     
   
  