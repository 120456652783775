@media (max-width:2000px) {
  .service-card{
    height: 320px;
    width: 460px;
  }
  .serviceDes{
    font-size: 1rem;
  }
  .read-more {
    height: 75px;
    padding: 20px 55px;
  }
}
@media (max-width:768px) {
  .read-more {
    height: 56px;
    padding: 10px 50px;
  }
  .service-card {
    width: 200px;
    height: 200px;
  }
}
@media (max-width:750px) {
  .service-card {
    width: 200px;
    height: 200px;
  }
}



@media screen and (max-width: 690px) {
  .service-card {
    height: 300px;
    width: 280px;
    margin-top: -20px;
  }
  .serviceDes{
    font-size: 0.77rem;
  }
  .sub{
    font-size: 0.63rem;
  }

  .serviceBtn button{
    padding: 8px 20px;
    margin-right: -0.2rem;
  }
}

       


@media screen and (max-width: 590px) {
  .service-card {
    height: 230px;
    width: 300px;
    margin-top: -20px;
  }
  .serviceDes{
    font-size: 0.77rem;
  }

  .sub{
    font-size: 0.50rem;
  }

  .read-more{
    height: 55px;
    padding: 10px 50px;
  }

  .serviceBtn button{
    padding: 8px 20px;
    margin-right: -0.2rem;
  }
}