.infromation{
  width: 660px;
}
.headquarter{
  width: 1359px;
}

.privacy-text{
  font-size: 35px;
  width: 1359px;
}

.terms{
  width: 1440px;
}

.details-img{
  height: 378px;
  width: 378px;
}

.member-card{
  width: 317px;
        height: 362px;
}

.service-card{
  Width: 700px;
  Height: 600px;
}

@media (max-width:750) {
  .max-width{
    width: 100vw;
  }
  .tabs{
    font-size: 10px;
  }
}




