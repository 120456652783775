.infoWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50vh;
    flex-direction: column;
  
  }
  .infoWrapper input{
      margin: 15px;
      width: 500px;
      height: 30px;
      /* position: absolute; */
      outline: none;
      z-index: -1;
  }
  
  .lastone input{
   width: 1080px;
  }
  
  
  .first{
      display: flex;
  }
  .second{
      display: flex;
  }
  
  .infoInput p{
      color: rgba(0, 0, 0, 0.671);
      margin-left: 15px;
     z-index: 10;
     margin-bottom: -10px;
     text-transform: uppercase;
  }
  .infoInput{
      background-color: white;
      margin: 25px;
      height: 60px;
  }
  
  
  .ContactInfoWrapper{
      
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 70vh;
    flex-direction: column;
    margin-top: -5rem;
  }
  
  .ContactInfoWrapper input{
      margin: 15px;
      width: 500px;
      height: 30px;
      outline: none;
  }
  
  .TextInput{
      background-color: white;
      margin: 25px;
      height: 80px;
  }
  
  .TextInput textarea{
      width: 1110px;
      outline: none;
      height: 200px;
      /* padding: 5px; */
      /* margin-bottom: 2rem;  */
  }
  
  .TextInput p{
      color: rgba(0, 0, 0, 0.671);
      margin-left: 1rem;
     text-transform: uppercase;
  }

  .submit-btn{
    margin-top: 4rem !important;
  }

  @media (max-width:800px) {
    .submit-btn{
        margin-top: 4rem !important;
      }
    
  }

  
  
  
  @media screen and (max-width:540px) {

    .infoWrapper{
        margin-top: 8rem;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 50vh;
        flex-direction: column;
      
      }
  
      .first{
          display: flex;
          flex-direction: column;
      }
      .second{
          display: flex;
          flex-direction: column;
  
      }
        .infoWrapper input{
            margin: 15px;
            
        }
  
      .infoWrapper input{
          margin: 15px;
          width: 300px;
          height: 30px;
          /* position: absolute; */
          outline: none;
          z-index: -1;
      }
  
      .TextInput textarea{
          width: 300px;
          outline: none;
          height: 200px;
          /* padding: 5px; */
          /* margin-bottom: 2rem;  */
      }

      .contact-text{
        margin-top:10rem;
      }
  
      .ContactInfoWrapper input{
          margin: 15px;
          width: 300px;
          height: 30px;
          outline: none;
      }
      .submit-btn{
            margin-top: 5rem !important;
      }
      
  }