@media (max-width:560px) {
  .project-card {
    width: 100vw;
    margin-bottom: 30px;
  }
}

@media (max-width:760px) {
    .project-card {
      width: 100vw;
      margin-bottom: 30px;
    }
  }
    
  
  @media (max-width:2000px) {
    .projectimg{
      width: 11vw;
      height: 11vw;
    }
  }
    
  
    
