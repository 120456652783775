@media (max-width:1032px) {
      
    .card-container {
        margin: 40px auto;
        width: 317px;
        height: 362px;
    }

    
}
@media (max-width:2000px) {
      
    .card-container {
        margin: 40px auto;
        width: 317px;
        height: 362px;
    }

    
}

  
@media (min-width:767px) and (max-width:1026px) {
    .homeimg {
        height: 180px
    }
   
   
      
    .card-container {
        margin: 60px auto;
        width: 317px;
        height: 362px;
    }
}
@media (max-width:580px) {
    .copy{
        font-size: 1rem;
    }
    

    .card-container {
        margin-bottom: 60px;
    }
}

@media (max-width:2000px) {
    
    .homeimg {
        height: 180px
    }
   
      
    .card-container {
        margin: 60px auto;
        width: 317px;
        height: 362px;
    }
}
    

