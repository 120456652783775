@media screen and (max-width: 540px) {
   

    .memberTxt{
        font-size: 1.1rem;
    }

    .memberBtn > .btnP{
        padding: 15px 15px ;
        margin-right: 0.1rem;
        font-size: 30px;
    }
    .memberBtn {
        /* padding: 2px 15px ; */
        margin-right: -0.5rem;
    }


  }
  @media screen and (max-width: 500px) {
    .member-card {
        margin-bottom: 40px;
    }

    .member-card img {
        height: 35vh;
    }

    .memberTxt{
        padding: 9px;
    }

    .memberBtn p{
        padding-top: 12px ;
        padding-bottom: 20px;
    }
    
  }
       